.amd-bs5 {
	.side-nav-template {
		display: flex;
		.side-nav-left, .side-nav-right {
			width: 18.75rem;
			flex-shrink: 0;
		}
		.side-nav-left {
			background-color: $neutral-90;
		}
		.side-nav-center {
			flex-grow: 1;
			min-width: 0;
			.footnotes {
				.footnote-container {
					background-color: unset;
					@include generate-spacing(padding, 45, 'b');
					@include media-breakpoint-up(xs) {
						@include generate-spacing(padding, 45, 'x');
					}
					@include media-breakpoint-up(xxl) {
						@include generate-spacing(padding, 70, 'x');
					}
				}
				--primary: black;
				--secondary: white;
				details {
					background-color: white;
					color: black;
					border: 1px solid #e1e1e2;
					border-radius: calc(.25rem - 1px);

					& > .container {
						padding-left: var(--bs-gutter-x, 1.0625rem);
						padding-right: var(--bs-gutter-x, 1.0625rem);
						max-width: 960px;
					}
					&> summary {
						color: black;
						border-bottom: 1px solid #e1e1e2;
						col {
							display: flex;
							justify-content: space-between;
						}
						&:hover {
							background-color: $neutral-20;
						}
						&>.container {
							padding-left: var(--bs-gutter-x,1.0625rem);
							padding-right: var(--bs-gutter-x,1.0625rem);
						}
					}
				}
			}
		}
		.side-nav-container {
			position: sticky;
			top: 0;
			/*overflow-y: auto;
			height: 100vh;*/
		}
	}
	
	@include media-breakpoint-down(xxl) {
		.side-nav-center {
			.container-lg { 
				padding-left: 1.625rem;
				padding-right: 1.625rem;
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.side-nav-template {
			flex-direction: column;
			.side-nav-left, .side-nav-center, .side-nav-right {
				width: 100%;
			}
			.side-nav-center {
				order: 2;
			}
			.side-nav-left {
				position: sticky;
				top: 0;
				z-index: 1026;
			}
			.side-nav-right {
				position: sticky;
				top: 56px;
				z-index: 1025;
			}
			.side-nav-container {
				height: 100%;
			}
		}
	}
}
