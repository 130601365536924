.amd-bs5 {
  .cmp-product-specs-table .datatable {
    table {
      &.dl-content-nowrap tr th {
        white-space: nowrap;
      }
      tr th {
        white-space: normal;
        select {
          max-width: 8rem;
        }
      }
    }
  }
  .tooltip.tooltip-lighter {
    .tooltip-inner, .tooltip-arrow {
      background: $neutral-90 !important;
    }
  }
  .product-tooltip {
    padding-left: 5px;
  }
}